<div class="profile-icons">
  <cx-profile-dropdown
    *ngIf="currentUser"
    [email]="currentUser.email"
    [firstname]="currentUser.firstname"
    [lastname]="currentUser.lastname"
  >
  </cx-profile-dropdown>
  <cx-menu *ngIf="userlist.length > 0">
    <cx-menu-item (click)="selectUser(user)" *ngFor="let user of userlist">
      {{ user.lastname }}
    </cx-menu-item>
  </cx-menu>
</div>
