import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoginPageComponent} from './login-page/login-page.component';
import {UIModule} from '../ui.module';
import {TranslateModule} from '@ngx-translate/core';
import {LoginPageRoutingModule} from './login-routing.module';
import {UserProfileComponent} from "./user-profile/user-profile.component";

@NgModule({
  declarations: [LoginPageComponent, UserProfileComponent],
  imports: [CommonModule, UIModule, TranslateModule, LoginPageRoutingModule],
  exports: [LoginPageComponent, UserProfileComponent],
})
export class LoginModule {
}
