import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { PageRequest } from '../../shared/model/page-request.model';
import { PageResult } from '../../shared/model/page-result.model';
import { OrderView } from '../order-view.model';
import { Order } from '../order.model';
import { OrderFilter } from '../order-overview/order-table/order-filter';

@Injectable({
  providedIn: 'root',
})
export class OrderHttpService {
  private readonly orderBaseUrl = `${environment.baseUrl}/order`;

  constructor(private httpClient: HttpClient) {}

  getAll(pageRequest: PageRequest): Observable<PageResult<OrderView>> {
    const params = pageRequest.toParams();
    return this.httpClient.get<PageResult<OrderView>>(
      `${this.orderBaseUrl}/view`,
      { params },
    );
  }

  getNewOrder(): Observable<string> {
    return this.httpClient
      .get<string[]>(`${this.orderBaseUrl}/new`)
      .pipe(map((id) => id[0]));
  }

  getOrderForId(id: string): Observable<Order> {
    return this.httpClient.get<Order>(`${this.orderBaseUrl}/${id}`);
  }

  save(order: Order, comment: string | undefined): Observable<string> {
    // It is not possible to simply send a string as a parameter. The comment is therefore packed into a JSON object.
    return this.httpClient
      .post<string[]>(`${this.orderBaseUrl}`, { order, comment })
      .pipe(map((id) => id[0]));
  }

  savePraxislogo(id: string, file: File): Observable<void> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.httpClient.post<void>(
      `${this.orderBaseUrl}/${id}/praxislogo`,
      formData,
    );
  }

  deletePraxislogo(orderId: string): Observable<void> {
    return this.httpClient.delete<void>(
      `${this.orderBaseUrl}/${orderId}/praxislogo`,
    );
  }

  getOrderPraxislogoThumbnail(id: string): Observable<Blob> {
    return this.httpClient.get(
      `${this.orderBaseUrl}/${id}/praxislogo/thumbnail`,
      { responseType: 'blob' },
    );
  }

  getFilterValues(): Observable<OrderFilter> {
    return this.httpClient.get<OrderFilter>(
      `${this.orderBaseUrl}/filterValues`,
    );
  }

  getAutocompleteOptions(): Observable<string[]> {
    return this.httpClient.get<string[]>(
      `${this.orderBaseUrl}/autocompleteOptions`,
    );
  }
}
