import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit,} from '@angular/core';
import {User} from '../../shared/model/user.model';
import {UserHttpService} from '../../shared/service/user-http.service';
import {Subject, takeUntil} from "rxjs";

@Component({
  selector: 'hpm-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrl: './user-profile.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserProfileComponent implements OnInit, OnDestroy {
  currentUser: User | null = null;
  userlist: User[] = [];
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private userService: UserHttpService,
    private cdr: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    this.loadUserlist();
    this.loadCurrentUser();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  selectUser(user: User): void {
    this.userService.switchUser(user);
  }

  private loadCurrentUser(): void {
    this.userService.getCurrentUser()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((currentUser) => {
        if (currentUser !== null) {
          this.currentUser = currentUser;
          this.cdr.detectChanges();
        }
      });
  }

  private loadUserlist(): void {
    this.userService.getUserList()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((userlist) => {
        this.userlist = userlist;
        this.cdr.detectChanges();
      });
  }
}
